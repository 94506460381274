import React, { useState, useEffect } from "react"
import { Box } from "@material-ui/core"
import { css, jsx } from "@emotion/core"
import Anime from "react-anime"
import HeroSvg from "../images/hero.svg"
import generateRandomInteger from "../utils/generateRandomInteger"
import _ from "lodash"

const Line = props => {
  const { posProps } = props

  return (
    <Anime
      key={11 + Date.now()}
      easing="linear"
      duration={posProps.duration}
      direction="normal"
      opacity={[
        {
          value: 1,
          duration: 10,
          delay: posProps.delay,
        },
      ]}
      x2={[
        {
          value: posProps.x2,
          duration: posProps.duration,
        },
      ]}
      loop={false}
      delay={posProps.delay}
      autoplay={true}
    >
      <line
        x1={posProps.x1}
        x2={posProps.x1}
        y1={posProps.y1}
        y2={posProps.y2}
        opacity={0}
        strokeWidth={posProps.strokeWidth}
        stroke="#FFFFFF"
        strokeLinecap="round"
      />
    </Anime>
  )
}

export default props => {
  let { width, height, offsetHeight } = props

  height = height - offsetHeight

  const heroWidth = width > 642 ? "642px" : (width - 30) + "px"

  const styles = css`
    padding-top: ${offsetHeight}px;

    .hero-svg-wrapper {
      width: ${heroWidth};
      height: 137px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  `

  const [lineSegments, setLineSegments] = useState([])

  useEffect(() => {
    let segments = []

    const svgWidth = width
    const svgHeight = height
    const lineSpacing = 40
    const lineLeading = 20
    const lineHeight = 20
    const rows = Math.floor(svgHeight / (lineHeight + lineLeading))

    for (let r = 0; r < rows; r++) {
      const rand = generateRandomInteger(1, 10)
      const spaces = rand + 1
      let availableSpace = svgWidth - spaces * lineSpacing

      const y1 = (lineLeading + lineHeight) * (r + 1)
      const y2 = y1

      for (let i = 0; i < rand; i++) {
        const length = generateRandomInteger(1, availableSpace)

        if (availableSpace <= 0) break

        const x1 = svgWidth - availableSpace - lineSpacing * (rand - i)
        let x2

        if (length >= availableSpace) {
          x2 = svgWidth - lineSpacing * 2
        } else {
          x2 = svgWidth - availableSpace - lineSpacing * (rand - i) + length
        }

        segments.push({ x1, x2, y1, y2, strokeWidth: lineHeight })
        availableSpace = availableSpace - length
      }
    }

    let delayInc = 0

    const lines = segments.map((segment, index) => {
      const duration = ((segment.x2 - segment.x1) / svgWidth) * 100 * 20
      const delay = delayInc
      delayInc += duration

      return {
        ...segment,
        delay,
        duration,
      }
    })

    setLineSegments(lines)
  }, [props])

  return (
    <Box className="morph-wrap" css={styles}>
      {!_.isEmpty(lineSegments) && width && height && (
        <Box position="relative">
          <svg width={width} height={height}>
            <defs>
              <mask id="linesMask" width={width} height={height}>
                {lineSegments.map((line, i) => (
                  <Line
                    key={i}
                    posProps={{
                      ...line,
                    }}
                  />
                ))}
              </mask>
            </defs>

            <rect
              x="20"
              y="20"
              width={width}
              height={height}
              fill="#B92607"
              mask="url(#linesMask)"
            />
          </svg>
          <Box position="absolute" className="hero-svg-wrapper">
            <HeroSvg />
          </Box>
        </Box>
      )}
    </Box>
  )
}

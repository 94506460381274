import { css } from "@emotion/core"
import { Box, Drawer, Grid, IconButton, Typography } from "@material-ui/core"
import MuiButton from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { withStyles } from "@material-ui/styles"
import Viewport from "@render-props/viewport"
import classNames from "classnames"
import { delay } from "lodash"
import React, { useCallback, useState } from "react"
import { FaBars } from "react-icons/fa"
import Mark from "../images/mark.svg"
import theme from "../theme/theme"

const Button = withStyles(theme => ({
  label: {
    textTransform: "none",
    color: theme.palette.common.white,
  },
}))(MuiButton)

const Header = ({
  getHeaderHeight,
  decorationVisible,
  setDecorationVisible,
  setIsClickScrolling,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [headerVisible, setHeaderVisible] = useState(true)
  const isSm = useMediaQuery("(min-width:600px")

  const measuredHeader = useCallback(node => {
    if (node !== null) {
      getHeaderHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const handleScroll = ({ direction }) => {
    const { y } = direction

    if (y === 1) {
      setHeaderVisible(false)
    } else if (y === -1) {
      setHeaderVisible(true)
    }
  }

  const headerClass = classNames("header", headerVisible ? "show" : "hide")

  const styles = css`
    background: ${theme.colors.primary};
    transition: top 0.7s ease-out;
    position: fixed;
    width: 100%;
    top: 0%;
    border-bottom: 1px solid #b92607;
    z-index: 999;

    &.hide {
      top: -100%;
    }

    .logo {
      padding: 15px 0 13px 30px;

      a {
        path {
          fill: #ffffff;
        }

        &:hover {
          path {
            fill: #f7b9ab;
          }
        }
      }
    }

    .nav {
      color: #ee694c;
      font-size: 14px;
      font-weight: 600;
      padding: 0 30px 0 0;
    }
  `
  const duration = 200

  const handleHomeClick = ({ scrollTo }) => {
    scrollTo(0, 0, { duration })
  }

  const handleAboutClick = ({ scrollY, scrollTo, height }) => {
    scrollTo(0, height, { duration })

    if (scrollY >= 10) {
      if (decorationVisible === false) setDecorationVisible(true)
    }
  }

  const handleContactClick = ({ scrollTo }) => {
    setIsClickScrolling(true)
    scrollTo(0, document.body.clientHeight, { duration })
    delay(() => setIsClickScrolling(false), duration)
  }

  const barsBtnStyles = css`
    padding-right: 15px;

    .MuiIconButton-label {
      color: #ffffff;
    }
  `

  const handleBarsClick = () => {
    setDrawerOpen(true)
  }

  const drawerStyles = css`
    .MuiPaper-root {
      background: ${theme.colors.primary};
      width: 80%;
      padding: 15px 0 0 15px;
    }
  `

  const handleHomeClickXs = ({ scrollTo }) => {
    handleHomeClick({ scrollTo })
    setDrawerOpen(false)
  }

  const handleAboutClickXs = ({ scrollY, scrollTo, height }) => {
    handleAboutClick({ scrollY, scrollTo, height })
    setDrawerOpen(false)
  }

  const handleContactClickXs = ({ scrollTo }) => {
    handleContactClick({ scrollTo })
    setDrawerOpen(false)
  }

  return (
    <Viewport>
      {({ scrollY, direction, scrollTo, height }) => (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          ref={measuredHeader}
          className={headerClass}
          onScroll={handleScroll({ scrollY, direction, scrollTo, height })}
          css={styles}
        >
          <Grid item xs={4} sm={1}>
            <div className="logo">
              <a href="#">
                <Mark />
              </a>
            </div>
          </Grid>
          {isSm ? (
            <Grid item sm={8}>
              <Typography variant="body2" className="nav" align="right">
                [{" "}
                <Button onClick={e => handleHomeClick({ scrollTo })}>
                  "home"
                </Button>
                ,{" "}
                <Button
                  onClick={e => handleAboutClick({ scrollY, scrollTo, height })}
                >
                  "about"
                </Button>
                , {/*<Button>"blog"</Button>,{" "}*/}
                <Button onClick={e => handleContactClick({ scrollTo })}>
                  "contact"
                </Button>{" "}
                ];
              </Typography>
            </Grid>
          ) : (
            <Grid item sm={8}>
              <Box css={barsBtnStyles}>
                <IconButton onClick={handleBarsClick}>
                  <FaBars />
                </IconButton>
              </Box>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                css={drawerStyles}
                transitionDuration={500}
              >
                <Typography variant="body2" className="nav" align="left">
                  [<br />
                  <Button onClick={e => handleHomeClickXs({ scrollTo })}>
                    "home"
                  </Button>
                  ,<br />
                  <Button
                    onClick={e =>
                      handleAboutClickXs({ scrollY, scrollTo, height })
                    }
                  >
                    "about"
                  </Button>
                  ,<br /> {/*<Button>"blog"</Button>,{" "}*/}
                  <Button onClick={e => handleContactClickXs({ scrollTo })}>
                    "contact"
                  </Button>
                  <br />
                  ];
                </Typography>
              </Drawer>
            </Grid>
          )}
        </Grid>
      )}
    </Viewport>
  )
}

export default Header

import React, { useState } from "react"
import { Box, Typography } from "@material-ui/core"
import { css, jsx } from "@emotion/core"
import theme from "../theme/theme"
import VisibilitySensor from "react-visibility-sensor"
import grey from "@material-ui/core/colors/grey"
import Anime from "react-anime"

const Skill = props => {
  const { icon, title, percentage, isSm } = props
  const [isVisible, setIsVisible] = useState(false)
  const barGrey = grey[400]

  const styles = css`
    img {
      max-width: 20px;
      max-height: 30px;
      margin: 0 20px 0 0;
    }

    .skill-title {
      width: 20%;
    }

    .gauge {
      height: 15px;
      width: calc(80% - 20px);
      background: ${barGrey};
    }

    .meter {
      background: ${theme.colors.info};
      height: 100%;
    }
  `

  const mobileStyles = css`
    margin-bottom: 30px;

    img {
      max-width: 20px;
      max-height: 30px;
      margin: 0;
    }

    .skill-title {
      width: 100%;
      text-align: center;
      margin: 15px 0;
    }

    .gauge {
      height: 15px;
      width: 100%;
      background: ${barGrey};
    }

    .meter {
      background: ${theme.colors.info};
      height: 100%;
    }
  `

  const handleChange = visible => {
    if (isVisible === false && visible === true) setIsVisible(true)
  }

  const flexDirection = isSm ? "row" : "column"

  return (
    <VisibilitySensor onChange={handleChange}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={flexDirection}
        css={isSm ? styles : mobileStyles}
      >
        <img src={icon} alt={title} />
        <Typography variant="body2" className="skill-title">
          {title}
        </Typography>
        <Box className="gauge" position="relative" borderRadius={20}>
          <Anime
            key={11 + Date.now()}
            easing="linear"
            duration={1000}
            direction="normal"
            width={[
              {
                value: `${percentage}%`,
                duration: 1000,
              },
            ]}
            loop={false}
          >
            {isVisible ? (
              <Box className="meter" position="absolute" borderRadius={20} />
            ) : (
              <Box />
            )}
          </Anime>
        </Box>
      </Box>
    </VisibilitySensor>
  )
}

export default Skill

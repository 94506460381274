import { css } from "@emotion/core"
import { Box, Typography, useMediaQuery } from "@material-ui/core"
import React from "react"
import BracketDown from "../images/bracket-down.svg"
import Bracket from "../images/bracket.svg"
import Image from "./image"

const faceStyles = css`
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: hidden;
`
const About = props => {
  const isSm = useMediaQuery("(min-width:600px")
  const flexDirection = isSm ? "row" : "column"

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      flexDirection={flexDirection}
      alignItems="center"
      py={5}
    >
      <Box className="face" width={150} height={150} css={faceStyles}>
        <Image />
      </Box>
      {isSm ? (
        <Box className="bracket" px={5}>
          <Bracket />
        </Box>
      ) : (
        <Box className="bracket" py={2}>
          <BracketDown />
        </Box>
      )}
      <Typography variant="body1">
        Hi, I'm Ashraf Slamang! I live in beautiful Cape Town, South Africa with
        my wife and two children and have gained years of valuable experience in
        the design and development industries here. With hard work, insight and
        all-round ability, I have been awarded and celebrated in every role I've
        taken on. Keen to find out more? Drop me an email at
        ashraf@copperseed.co.za.
      </Typography>
    </Box>
  )
}

export default About

import { css } from "@emotion/core"
import {
  Box,
  Container,
  createMuiTheme,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import { ThemeProvider } from "@material-ui/styles"
import Viewport, { ViewportSize } from "@render-props/viewport"
import React, { useState } from "react"
import Anime from "react-anime"
import { Helmet } from "react-helmet"
import education from "../../content/education.json"
import skills from "../../content/skills.json"
import About from "../components/about"
import Education from "../components/education"
import Form from "../components/form"
import Header from "../components/header"
import Hero from "../components/hero"
import "../components/layout.css"
import Skill from "../components/skill"
import Decoration from "../images/decoration.svg"
import theme from "../theme/theme"

const styles = css`
  background: ${theme.colors.primary};
  position: relative;
  z-index: 100;

  a {
    color: #ffffff;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #f7b9ab;
    }
  }
`

const footerStyles = css`
  background: ${theme.colors.primary};
  color: #ffffff;
`

const muiTheme = createMuiTheme({ ...theme })

const IndexPage = () => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [decorationVisible, setDecorationVisible] = useState(false)
  const [isClickScrolling, setIsClickScrolling] = useState(false)

  const isSm = useMediaQuery("(min-width:600px")

  const handleScroll = ({ scrollY, direction, scrollTo, height }) => {
    const { y } = direction
    const duration = 100

    if (y === 1 && scrollY > 10 && scrollY < height)
      scrollTo(0, height, { duration })

    if (scrollY >= 10) {
      if (decorationVisible === false) setDecorationVisible(true)
    }
  }

  const getHeight = height => {
    setHeaderHeight(height)
  }

  const { languages, frameworks } = skills

  return (
    <ThemeProvider theme={muiTheme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ashraf: Experienced developer seeking remote work</title>
      </Helmet>
      <ViewportSize>
        {({ width, height }) => (
          <div css={styles}>
            <Header
              getHeaderHeight={getHeight}
              decorationVisible={decorationVisible}
              setDecorationVisible={setDecorationVisible}
              setIsClickScrolling={setIsClickScrolling}
            />
            <Hero width={width} height={height} offsetHeight={headerHeight} />
          </div>
        )}
      </ViewportSize>
      <Viewport>
        {({ scrollY, direction, scrollTo, height }) => (
          <Box
            display="none"
            onScroll={handleScroll({ scrollY, direction, scrollTo, height })}
          />
        )}
      </Viewport>
      <Container fixed>
        <Box className="about" position="relative" overflow="visible">
          <Anime
            key={11 + Date.now()}
            easing="linear"
            duration={2000}
            direction="normal"
            top={[
              {
                value: "0px",
                duration: 2000,
              },
            ]}
            loop={false}
          >
            {decorationVisible ? (
              <Box
                className="decoration"
                style={{ top: `-100%` }}
                left={-100}
                position="absolute"
              >
                <Decoration />
              </Box>
            ) : (
              <Box />
            )}
          </Anime>
          <About />
          <Box className="skills">
            <Box mb={4}>
              <Typography
                component="h2"
                variant="h1"
                color="primary"
                gutterBottom
              >
                Skills
              </Typography>
              <Typography
                component="h3"
                variant="h2"
                color="secondary"
                gutterBottom
              >
                Languages
              </Typography>
            </Box>
            <Grid container spacing={isSm && 6}>
              {languages.map(({ icon, title, percentage }) => {
                return (
                  <Grid key={title} item xs={12} lg={6}>
                    <Skill
                      icon={icon}
                      title={title}
                      percentage={percentage}
                      isSm={isSm}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Box mt={9} mb={4}>
              <Typography
                component="h3"
                variant="h2"
                color="secondary"
                gutterBottom
              >
                Libraries &amp; Frameworks
              </Typography>
            </Box>
            <Grid container spacing={isSm && 6}>
              {frameworks.map(({ icon, title, percentage }) => {
                return (
                  <Grid key={title} item xs={12} lg={6}>
                    <Skill
                      icon={icon}
                      title={title}
                      percentage={percentage}
                      isSm={isSm}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Box mt={9} mb={4}>
              <Typography
                component="h2"
                variant="h1"
                color="primary"
                gutterBottom
              >
                Education
              </Typography>
            </Box>
            <Box mb={5}>
              {education.map(({ yearStart, yearEnd, course, institute }) => (
                <Education
                  key={course}
                  yearStart={yearStart}
                  yearEnd={yearEnd}
                  course={course}
                  institute={institute}
                  isSm={isSm}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box css={footerStyles} pt={5} pb={2}>
        <Container fixed>
          <Typography component="h2" variant="h1" color="inherit" gutterBottom>
            Contact Me
          </Typography>
          <Form isSm={isSm} />
          <Box mt={5}>
            <Typography variant="overline">
              &copy; 2020 Ashraf Slamang
            </Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default IndexPage

import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import { makeStyles, Box, Button } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  inputLabel: {
    color: theme.palette.common.white,
    "&$formLabelFocused": {
      color: theme.palette.common.white,
    },
  },
  formLabelFocused: {},
  underline: {
    "&:before": {
      borderBottom: `1px solid ${theme.palette.common.white}`,
    },
    "&:after": {
      borderBottom: `2px solid ${theme.palette.common.white}`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: `2px solid ${theme.palette.common.white}`,
    },
  },
  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    borderRadius: 20,
  },
}))

const Form = props => {
  const { isSm } = props
  const [nameValue, setNameValue] = useState("")
  const [nameError, setNameError] = useState(null)
  const [emailValue, setEmailValue] = useState("")
  const [emailError, setEmailError] = useState(null)
  const [messageValue, setMessageValue] = useState("")
  const [messageError, setMessageError] = useState(null)
  const classes = useStyles()
  const handleSubmit = e => {
    setNameError(null);
    setEmailError(null);
    setMessageError(null);

    if (!nameValue) {
      e.preventDefault()
      setNameError("Name is required")
    }

    if (!emailValue) {
      e.preventDefault()
      setEmailError("Email is required")
    }

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isEmail = re.test(String(emailValue).toLowerCase())

    if (!isEmail) {
      e.preventDefault()
      setEmailError("Invalid email address")
    }

    if (!messageValue) {
      e.preventDefault();
      setMessageError("Message is required")
    }
  }

  return (
    <form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <Box width={isSm ? "50%" : "100%"}>
        <TextField
          fullWidth
          value={nameValue}
          onChange={e => setNameValue(e.currentTarget.value)}
          label="Name"
          name="name"
          required
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
              focused: classes.formLabelFocused,
            },
          }}
          InputProps={{
            classes: { root: classes.inputLabel, underline: classes.underline },
          }}
        />
        <Box mt={2}>
          <TextField
            fullWidth
            value={emailValue}
            onChange={e => setEmailValue(e.currentTarget.value)}
            label="Email address"
            name="email"
            required
            type="email"
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: classes.formLabelFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.inputLabel,
                underline: classes.underline,
              },
            }}
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            value={messageValue}
            onChange={e => setMessageValue(e.currentTarget.value)}
            label="Message"
            name="message"
            required
            multiline
            rowsMax={3}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: classes.formLabelFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.inputLabel,
                underline: classes.underline,
              },
            }}
          />
        </Box>
      </Box>
      <Box mt={4}>
        <Button className={classes.button} variant="outlined" type="submit">
          Send
        </Button>
      </Box>
    </form>
  )
}

export default Form

import React from "react"
import { Box, Typography } from "@material-ui/core"
import { FaArrowCircleRight } from "react-icons/fa"
import { useTheme } from "@material-ui/styles"
import { css, jsx } from "@emotion/core"

const Education = props => {
  const { yearStart, yearEnd, course, institute, isSm } = props
  const theme = useTheme()
  const yearsWidth = isSm ? "135px" : "none"
  const yearsMargin = isSm ? "auto" : "15px"
  const slashesMargin = isSm ? "auto" : "15px 0"
  const styles = css`
    .years {
      background: ${theme.colors.highlight};
      color: #fff;
      min-width: ${yearsWidth};
      margin-bottom: ${yearsMargin};
    }

    .course {
      font-weight: bold;
    }

    .institute {
      font-weight: 200;
    }

    .slashes {
      color: ${theme.colors.primary};
      font-weight: bold;
      margin: ${slashesMargin};
    }
  `

  const flexDirection = isSm ? "row" : "column"

  return (
    <Box
      display="flex"
      mb={2}
      flexDirection={flexDirection}
      alignItems="center"
      css={styles}
    >
      <Box display="flex" alignItems="center" className="years" p={1}>
        <Box mr={1}>
          <Typography>{yearStart}</Typography>
        </Box>
        <FaArrowCircleRight />
        <Box ml={1}>
          <Typography>{yearEnd}</Typography>
        </Box>
      </Box>
      <Box mx={2}>
        <Typography className="course">{course}</Typography>
      </Box>
      <Box>
        <Typography className="slashes" variant="h5" component="body1">
          //
        </Typography>
      </Box>
      <Box mx={2}>
        <Typography className="institute" align={isSm ? "left" : "center"}>{institute}</Typography>
      </Box>
    </Box>
  )
}

export default Education

const primary = '#D62F0B';
const secondary = '#C44900';
const info = '#14302E';
const highlight = '#42143E';

export default {
    palette: {
        primary: {
            main: primary
        },
        secondary: {
            main: secondary
        },
        info: {
            main: info
        },
        highlight: {
            main: highlight
        }
    },
    colors: {
        primary,
        secondary,
        info,
        highlight
    },
    typography: {
        fontSize: 13,
        h1: {
            fontSize: '2rem'
        },
        h2: {
            fontSize: '1.3333333333333333rem'
        },
        fontFamily: [
            'Montserrat',
            'Roboto',
            '"Helvetica Neue"',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
};